<template>
  <div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4"><h6 class="pb-3">{{ $t('doc_payment_details.doc_code') }}: {{ data.code }}</h6></div>
          <div class="col-md-4"><h6 class="pb-3">{{ $t('doc_payment_details.doc_type') }}: {{ data.class_name }}</h6></div>
        </div>
        <div class="row">
          <div class="col-md-4"><h6 class="pb-3">{{ $t('doc_payment_details.amount') }}: {{ data.amount }}</h6></div>
          <div class="col-md-4"><h6 class="pb-3">{{ $t('doc_payment_details.currency') }}: {{ data.currency_name }}</h6></div>
          <div class="col-md-4"><h6 class="pb-3">{{ $t('doc_payment_details.create_date') }}: {{ data.created_at }}</h6></div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6>{{ $t('doc_payment_details.invoices') }}</h6>
            </div>
            <div>
              <table class="table text-center">
                <thead>
                <tr>
                  <th>{{ $t('doc_payment_details.invoice_code') }}</th>
                  <th>{{ $t('doc_payment_details.invoice_type') }}</th>
                  <th>{{ $t('doc_payment_details.amount') }}</th>
                  <th>{{ $t('doc_payment_details.currency') }}</th>
                  <th>{{ $t('doc_payment_details.create_date') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row,index) in invoices" :key="'invoices'+index">
                  <td>{{ row.invoice_code }}</td>
                  <td>{{ row.type }}</td>
                  <td>{{ row.invoice_total }}</td>
                  <td>{{ row.currency_name }}</td>
                  <td>{{ row.created_at }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6>{{ $t('doc_payment_details.balance') }}</h6>
            </div>
            <div>
              <table class="table text-center">
                <thead>
                <tr>
                  <th>{{ $t('doc_payment_details.type') }}</th>
                  <th>{{ $t('doc_payment_details.direction') }}</th>
                  <th>{{ $t('doc_payment_details.amount') }}</th>
                  <th>{{ $t('doc_payment_details.currency') }}</th>
                  <th>{{ $t('doc_payment_details.create_date') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row,index) in attached_balance" :key="'attached_balance'+index">
                  <td>{{ row.type }}</td>
                  <td>{{ row.route }}</td>
                  <td>{{ row.amount }}</td>
                  <td>{{ row.currency_name }}</td>
                  <td>{{ row.created_at }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "view-doc-payment-details",
  data() {
    return {
      mainRoute: 'finances/expenses_income/payment_details',
      id: this.$route.params.id ? this.$route.params.id : null,
      data: {},
      invoices: [],
      attached_balance: [],
    };
  },

  methods: {
    async getData() {
      ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
        this.data = response.data.data;
        this.invoices = response.data.data.invoices ? response.data.data.invoices : [];
        this.attached_balance = response.data.data.attached_balance ? response.data.data.attached_balance : [];
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.expenses_income_transaction"), route: '/finances/expenses-income-transaction'}, {title: this.$t('doc_payment_details.payment_details')}]);

    if (this.id) {
      this.getData();
    }

  },
};
</script>